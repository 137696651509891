<script setup></script>

<template>
  <div class="min-h-screen bg-white">
    <Notif />
    <div class="">
      <div class="flex w-full justify-center border p-6">
        <img
          src="/images/flik-logo-black.svg"
          alt="Flik Logo"
          class="h-6 object-contain"
        />
      </div>
      <div class="container mx-auto">
        <slot />
      </div>
    </div>
  </div>
</template>
